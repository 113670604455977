import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Nav from './nav';
import { breakpoints } from '../utils';

const HeaderWrapper = styled.header`
  border-bottom: 1px solid #ccc;
  margin-bottom: 1.45rem;

  @media ${breakpoints.large} {
    margin-bottom: 3.21rem;
  }
`;

const Header = ({ siteTitle, siteDescription }) => (
  <HeaderWrapper>
    <Nav siteTitle={siteTitle} siteDescription={siteDescription} />
  </HeaderWrapper>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;

import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { breakpoints } from '../utils';

const NavBar = styled.nav`
  margin: 0 auto;
  max-width: 960px;
  display: flex;
  padding: 1.45rem 1.0875rem;
  align-items: center;

  @media ${breakpoints.large} {
    padding: 3.21rem 1.0875rem;
  }
`;

const NavList = styled.ul`
  list-style: none;
  margin-bottom: 0;
  display: flex;

  @media ${breakpoints.small} {
    margin-left: 4rem;
  }
`;

const NavItem = styled.li`
  display: block;
  margin-right: 1rem;
  margin-bottom: 0;
  position: relative;

  @media ${breakpoints.medium} {
    margin-right: 2rem;
  }
`;

const NavLink = styled(Link)`
  color: black;
  text-transform: lowercase;
  font-size: 1rem;

  @media ${breakpoints.medium} {
    font-size: 1.2rem;
  }

  @media ${breakpoints.large} {
    font-size: 1.4rem;
  }

  &:hover {
    color: rebeccapurple;
  }
`;

const Brand = styled.h3`
  margin-bottom: 0;
  font-size: 1rem;

  @media ${breakpoints.medium} {
    font-size: 1.6rem;
  }

  @media ${breakpoints.large} {
    font-size: 2rem;
  }
`;

const BrandLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  color: rebeccapurple;
`;

const BrandDescription = styled.small`
  display: block;
  font-weight: normal;
  color: #444;
  font-size: 0.6rem;

  @media ${breakpoints.medium} {
    font-size: 0.8rem;
  }

  @media ${breakpoints.large} {
    font-size: 1rem;
  }
`;

const Nav = ({ siteTitle, siteDescription }) => {
  return (
    <NavBar>
      <Brand>
        <BrandLink to="/">{siteTitle}</BrandLink>
        <BrandDescription>{siteDescription}</BrandDescription>
      </Brand>
      <NavList>
        <NavItem>
          <NavLink to="/blog">Blog</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/about">About</NavLink>
        </NavItem>
      </NavList>
    </NavBar>
  );
};

export default Nav;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { breakpoints } from '../utils';

const Tag = styled(Link)`
  border: none;
  border: 1px solid black;
  border-radius: 3px;
  box-shadow: none;
  color: rebeccapurple;
  text-decoration: none;
  margin: 5px;
  padding: 5px 10px;
  font-size: 0.8rem;
  word-wrap: normal;
  display: inline-block;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    background-color: rebeccapurple;
    color: white;
  }

  @media ${breakpoints.small} {
    font-size: 0.9rem;
  }
`;

const TagList = ({ tags }) => {
  return (
    <>
      {tags.split(',').map(tag => (
        <Tag key={tag} to={`/blog?tag=${tag}`}>
          #{tag}
        </Tag>
      ))}
    </>
  );
};

export default TagList;
